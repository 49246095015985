/* src/app/Body/introduction/introduction.component.scss */
:host {
  display: block;
  position: relative;
  width: 100%;
}
.introduction-image {
  display: block;
  position: relative;
  width: 100%;
}
.introduction-text {
  color: white;
  font-family: "Raleway", serif;
  font-size: 1.3em;
  padding: 10px;
  text-align: -webkit-center;
  text-align: center;
}
.introduction-slogan {
  color: white;
  font-family: "Satisfy", serif;
  font-size: 1.7em;
  text-align: -webkit-center;
  text-align: center;
}
/*# sourceMappingURL=introduction.component.css.map */
