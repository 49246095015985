/* src/app/Body/ueber-uns/ueber-uns.component.scss */
.ueber-uns-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-items: center;
  overflow: hidden;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  word-wrap: break-word;
  height: 100%;
  font-family: Verdana;
  text-align: -webkit-center;
  text-align: center;
  letter-spacing: 0.25px;
  line-height: 1.5;
  color: #6D7073;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  font-style: normal;
  font-size: 16px;
  line-height: 1.625rem;
  font-family:
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
}
.zitat-text-wrapper {
  display: flex;
  padding: 0 20%;
  flex-direction: column;
}
.zitat {
  height: auto;
  margin-top: 2rem;
  font-style: italic;
  font-family: Georgia;
  color: white;
}
.zitat-Autor {
  margin-top: 0px;
  height: auto;
  font-size: 1.5em;
  color: white;
}
.beschreibung-wrapper {
  display: flex;
  padding: 10%;
}
.beschreibung {
  text-align: -webkit-center;
  text-align: center;
  font-size: 130%;
  line-height: 2.6rem;
}
/*# sourceMappingURL=ueber-uns.component.css.map */
