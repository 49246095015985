<mat-toolbar color="primary">
    <mat-toolbar-row class="toolbar-content" 
                    [ngClass] ="{'toolbar-content-handset' : !isDesktop}"
        >
        <button type="button"
                aria-label="toggle sidenvav"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="!isDesktop"
                >
                <mat-icon aria-label="side nav toggle-icon">more_vert</mat-icon>
        </button>
        <button [routerLink]="['/ueberUns']" mat-button color="#5D4037" class="product-tab" [ngClass] ="{'hidden-nav' : !isDesktop}">Über Uns</button>
        <button [matMenuTriggerFor]="menu" mat-button color="#5D4037" class="product-tab"[ngClass] ="{'hidden-nav' : !isDesktop}">Referenzen</button>
        <img [routerLink]="['/startPage']" class="website-logo" src="..//../../assets/images/zimmerei_logo.png" [ngClass] ="{'hidden-nav' : !isDesktop}">
        <button [routerLink]="['/steinbruchhaeusel']" mat-button color="#5D4037" class="product-tab" [ngClass] ="{'hidden-nav' : !isDesktop}">Ferienwohnung</button>
        <button [routerLink]="['/kontakt']" mat-button color="#5D4037" class="product-tab"[ngClass] ="{'hidden-nav' : !isDesktop}">Kontakt</button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menu="matMenu">
    <button mat-menu-item>Carports</button>
    <button mat-menu-item [routerLink]="['/daecher']">Dächer</button>
    <button mat-menu-item [routerLink]="['/steinbruchhaeusel']">Steinbruchhäusel</button>
    <button mat-menu-item [routerLink]="['/balkenBetten']">Balkenbetten</button>
</mat-menu>