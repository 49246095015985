<div class="spotlight-content-container">
    <div class="image-container">
        <img class="spotlight-image" src="../../../../assets/images/balkenbett_cropped.jpg">
        <div class="spotlight-text-container">
            <p class="spotlight-header-text" [ngClass]="{'desktop-header': isDesktop, 'phone-header': !isDesktop}">Balkenbetten</p>
            <p class="spotlight-text" [ngClass]="{'desktop': isDesktop, 'phone': !isDesktop}">Kunst beim Liegen</p>
        </div>
    </div>
    <div class="text-container">
        <p class="spotlight-text kursiv" [ngClass]="{'desktop': isDesktop, 'phone': !isDesktop}">
            "Wie alles was wir tun, sind auch die Balkenbetten mit Liebe angefertigt. Und weil wir
            so davon überzeugt sind, nutzen wir sie selbst!"</p>
    </div>
    <div class="card-container">
        <mat-card>
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="..//../../assets/images/balkenbett_01.jpg">
        </mat-card>
        <mat-card>
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="..//../../assets/images/balkenbett_02.jpg">
          </mat-card>
    </div>
</div>