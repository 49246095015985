/* src/app/Body/kontakt/kontakt.component.scss */
.kontakt-wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  column-gap: 1em;
  row-gap: 1em;
}
.kontakt-header {
  grid-row: 1/2;
  font-family: Helvetica, sans-serif;
  font-size: 2em;
  justify-self: center;
}
.kontakt-beschreibung {
  display: grid;
  grid-column: auto;
  grid-row: 2/3;
  width: 100%;
  justify-items: center;
  column-gap: 1em;
}
.anschrift {
  grid-column: 1/2;
  margin: 3rem 0em;
}
.email {
  text-decoration: none;
  color: #5d5d5d;
  font-family:
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
}
p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  font-family:
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #5d5d5d;
}
/*# sourceMappingURL=kontakt.component.css.map */
