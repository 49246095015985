/* src/app/Footer/footer/footer.component.scss */
.toolbar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: center;
}
.footer-tab {
  position: relative;
  color: white !important;
}
/*# sourceMappingURL=footer.component.css.map */
