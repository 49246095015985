import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ueber-uns',
  templateUrl: './ueber-uns.component.html',
  styleUrls: ['./ueber-uns.component.scss']
})
export class UeberUnsComponent implements OnInit {

  public Beschreibung = `Wer Christian Wabnitz kennt,
                        weiß, dass dieses Zitat von Konfuzius hundertprozentig auf ihn zutrifft.
                        Er ist, im wahrsten Sinne des Wortes, ein passionierter Zimmermann.
                        Mit großer Hingabe und künstlerischem Geschick lebt er seine Leidenschaft für dieses Handwerk.
                        Kein Typ, der Schwierigkeiten ausweicht, sodass er in den Zeiten der DDR-Mangelwirtschaft
                        die Herausforderung angenommen hat
                        und seinen Traum vom selbst gebauten Umgebindehaus Wirklichkeit werden ließ.
                        Heute ist die Werkstatt der Zimmerei Teil dieses Umgebindehauses.
                        Im Dezember 1999 hat Christian erfolgreich seine Meisterprüfung abgelegt und
                        im Mai 2003 den Sprung ins marktwirtschaftliche Neuland gewagt. Zunächst als visionärer Einzelkämpfer,
                        aber die wachsende Auftragslage und der steigende Bekanntheitsgrad führten dazu,
                        dass Christian gezielt nach gutem Nachwuchs im Zimmererhandwerk suchte.
                        Auch als Ausbildungsbetrieb war die Zimmerei zur Gewinnung neuer Fachkräfte tätig.
                        Inzwischen umfasst die Firma neben ihm noch 3 junge engagierte Gesellen.
                        Mit dieser Mannschaft geht jeder hölzerne Traum in Erfüllung!`;

public Zitat = '"Wähle einen Beruf, den Du liebst, und Du brauchst keinen Tag in Deinem Leben mehr zu arbeiten."';
public ZitatAutor = '(Konfuzius)';

  constructor() { }

  ngOnInit() {
  }

}
