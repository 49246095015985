<div class=kontakt-wrapper>
  <p class=kontakt-header>
    Kontakt
  </p>
  <div class=kontakt-beschreibung>
    <div class=anschrift>
      <!-- <p class=ueberschrift>Anschrift</p> -->
      <p>Feldhäuser 3</p>
      <p>02747 Berthelsdorf / OT Rennersdorf</p>
      <br>
      <p>Tel: 035873 / 42739</p>
      <p>Mobil: 0172 / 1494939</p>
      <br>
      <a class=email href= "mailto:info@zimmerei-am-berge.de">Email: info&#64;zimmerei-am-berge.de</a>
    </div>
  </div>
</div>