import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

// Eigene Componenten
import { SpotlightComponent } from './Body/spotlight/spotlight.component';
import { BodyComponent } from './Body/body/body.component';
import { IntroductionComponent } from './Body/introduction/introduction.component';
import { FooterComponent } from './Footer/footer/footer.component';
import { KontaktComponent } from './Body/kontakt/kontakt.component';
import { ImpressumComponent } from './Body/impressum/impressum.component';
import { DrawerToolbarComponent } from './Header/drawer-toolbar/drawer-toolbar.component';
import { HeaderToolbarComponent } from './Header/header-toolbar/header-toolbar.component';
import { DaecherComponent } from './Body/referenzen/daecher/daecher.component';
import { SteinbruchhhaeuselComponent } from './Body/referenzen/steinbruchhhaeusel/steinbruchhhaeusel.component';
import { BalkenbettenComponent } from './Body/referenzen/balkenbetten/balkenbetten.component';

// Material - Imports//
import { MatCardModule } from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UeberUnsComponent } from './Body/ueber-uns/ueber-uns.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LayoutModule } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { PageIntroductionComponent } from './Body/page-introduction/page-introduction.component';

@NgModule({
	declarations: [
		AppComponent,
		SpotlightComponent,
		BodyComponent,
		IntroductionComponent,
		FooterComponent,
		UeberUnsComponent,
		KontaktComponent,
		ImpressumComponent,
		DrawerToolbarComponent,
		HeaderToolbarComponent,
		DaecherComponent,
		SteinbruchhhaeuselComponent,
		BalkenbettenComponent,
		PageIntroductionComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MatToolbarModule,
		MatCardModule,
		MatButtonModule,
		MatMenuModule,
		BrowserAnimationsModule,
		MatSidenavModule,
		LayoutModule,
		MatIconModule,
		MatExpansionModule,
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
