<div class='impressum'>
    <h1>Impressum</h1>
    <p>Angaben gemäß § 5 TMG</p>
    <p>Christian Wabnitz <br>
        Feldhäuser 3<br>
        02747 Herrnhut OT Rennersdorf <br>
    </p>
    <p> <strong>Vertreten durch: </strong><br>
        Christian Wabnitz<br>
    </p>
    <p><strong>Kontakt:</strong> <br>
        Telefon: 035873-42739<br>
        Fax: 035873-36238<br>
        E-Mail: <a href='mailto:info@zimmerei-am-berge.de'>info&#64;zimmerei-am-berge.de</a></p>
    <p><strong>Umsatzsteuer-ID: </strong> <br>
        Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE227709796<br><br>
        <strong>Wirtschafts-ID: </strong><br>
        <br>
    </p>
    <p><strong>Aufsichtsbehörde:</strong><br>
        Handwerkskammer Dresden Am Lagerplatz A01099 Dresden<br></p><br>
    Website Impressum von <a href="https://www.impressum-generator.de">impressum-generator.de</a>
</div>