/* src/app/Body/referenzen/steinbruchhhaeusel/steinbruchhhaeusel.component.scss */
:host {
  display: flex;
  flex-direction: column;
  gap: 0px;
  position: relative;
}
mat-card {
  max-width: 400px;
  margin: 10px;
}
.page-introduction {
  position: relative;
}
.steinbruchhaus-bilder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  flex: 1 1 0;
}
.steinbruchhaus-intro-image {
  position: relative;
  width: 100%;
  flex: 0 1 600px;
  height: 100vh;
  object-fit: cover;
}
.welcome-container {
  background-color: rgba(215, 204, 200, 0.6);
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  left: 0%;
  padding:
    10,
    10,
    10,
    0px;
  position: absolute;
  top: 30%;
  width: 100%;
}
.welcome-text {
  font-weight: 450;
  font-size: 3em;
  width: 80%;
  margin: 0;
  color: white;
}
.welcome-text-subtitle {
  font-size: 1.2em;
  font-weight: 200;
  color: white;
}
.steinbruchhaus-intro-container {
  flex: 1 1 0;
  align-self: center;
  width: 60%;
}
.steinbruchhaus-intro-text {
  font-family:
    Helvetica,
    Arial,
    sans-serif;
  color: #6D7073;
  word-break: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  font-size: 130%;
  line-height: 2.6rem;
  text-align: justify;
}
.accent-color {
  background-color: #ffc107;
}
.greetings {
  font-weight: 550;
}
.names {
  font-size: 140%;
}
.intro-phone {
  width: 80%;
}
.phone {
  font-size: 100%;
  line-height: 2rem;
}
.welcome-phone {
  font-size: 2.3em;
}
/*# sourceMappingURL=steinbruchhhaeusel.component.css.map */
