<div class="introduction">
    <img class="introduction-image"
    [ngClass]="{'height-isCalculated-by-view': useViewAsHeightCalculationBase}"
    [src]="image"
    >
    <div class="introduction-block" 
         [ngClass]="{'phone': !isDesktop}">
        <ng-content></ng-content>
    </div>
</div>
