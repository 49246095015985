<app-header-toolbar [isDesktop]="isDesktop" [drawer]="drawer"></app-header-toolbar>
<mat-sidenav-container>
	<mat-sidenav #drawer 
				class='sidenav' 
				[ngClass] ="{'hidden-nav' : isDesktop}"
				fixedInViewport='false'
				[mode]="'over'" 
				[opened]="!isDesktop">
				<app-drawer-toolbar></app-drawer-toolbar>
	</mat-sidenav>
	<mat-sidenav-content>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
<app-footer></app-footer>
