/* src/app/Body/impressum/impressum.component.scss */
:host {
  display: flex;
  flex-direction: column;
  margin: 0;
}
:host .impressum {
  text-align: -webkit-center;
  text-align: center;
}
:host .impressum p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  font-family:
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #5d5d5d;
}
:host .impressum a {
  text-decoration: none;
  color: #5d5d5d;
  font-family:
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
}
/*# sourceMappingURL=impressum.component.css.map */
