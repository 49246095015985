<div class="spotlight-wrapper">
  <div class= "spotlight-text-wrapper">
      <h1 class="spotlight-headline mat-h1">{{headline}}</h1>
      <p class="spotlight-textbody body-1">
        {{description}}
      </p>
      <button [routerLink]="link" class="spotlight-button" mat-stroked-button color="accent">Erfahren Sie mehr</button>
  </div>
  <div class="spotlight-image-wrapper">
  <img class="spotlight-image" src="{{imageSource}}">
  </div>
</div>
<div class="spotlight-divider"></div> 