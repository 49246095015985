<app-page-introduction [image]="'..//../../assets/images/christian_werkstatt.jpg'">
  <p  class="introduction-text">
    Traditioneller Holzbau, Errichten und Bewahren der denkmalgeschützten Umgebindehäuser.
  </p>
  <h2  class="introduction-slogan">
      Das ist unser Handwerk
  </h2>
  <p  *ngIf="isDesktop" class="introduction-text">
      Verziert oder ohne Schnörkel geben wir dem Holz die sorgsame Bearbeitung, die es verdient und dem Kunden das, was er sich wünscht: 
  </p>
  <h2 *ngIf="isDesktop" class="introduction-slogan">
      Holz in seinen schönsten Formen
  </h2>
</app-page-introduction>
