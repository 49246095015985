/* src/app/Body/body/body.component.scss */
:host {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.inactive {
  display: none;
}
.active {
  display: flex;
}
.spotlight-container {
  position: relative;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin: 0 16px;
  color: white;
  transition: 0.6s ease;
  -webkit-user-select: none;
  user-select: none;
}
.next {
  right: 0;
}
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.dot-container {
  text-align: center;
}
.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  margin-top: 16px;
  border-radius: 50%;
  border: solid 1px #ffc107;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.filled {
  background-color: #ffc107;
}
.empty {
  background-color: transparent;
}
/*# sourceMappingURL=body.component.css.map */
