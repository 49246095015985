<div class="daecher-bilder">
    <mat-card>
      <mat-card-header>
      </mat-card-header>
      <img mat-card-image src="..//../../assets/images/gartenhaus1.jpg">
    </mat-card>
  
    <mat-card>
      <mat-card-header>
      </mat-card-header>
      <img mat-card-image src="..//../../assets/images/gartenhaus2.jpg">
    </mat-card>
  
    <mat-card>
      <mat-card-header>
      </mat-card-header>
      <img mat-card-image src="..//..//../assets/images/pavillion1.jpg">
    </mat-card>
  </div>