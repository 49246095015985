/* src/app/Body/referenzen/daecher/daecher.component.css */
mat-card {
  max-width: 400px;
  margin: 10px;
}
.daecher-bilder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
/*# sourceMappingURL=daecher.component.css.map */
