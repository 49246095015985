import { Component, Input } from '@angular/core';
import { ScreenSizeService } from 'src/app/screen-size.service';

@Component({
  selector: 'app-page-introduction',
  templateUrl: './page-introduction.component.html',
  styleUrls: ['./page-introduction.component.css']
})
export class PageIntroductionComponent {
  isDesktop: boolean;

  @Input()
  image: string;

  @Input()
  useViewAsHeightCalculationBase: boolean;

  constructor(private screenSizeService: ScreenSizeService) { 
    this.screenSizeService.screenSizeObserver$.subscribe(isDesktop => {this.isDesktop = isDesktop;});
  }
}
