/* src/app/Header/header-toolbar/header-toolbar.component.css */
.toolbar-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1em;
}
.toolbar-content-handset {
  justify-content: start;
}
.product-tab {
  position: relative;
  color: white !important;
}
.product-tab:hover {
  color: rgb(254, 227, 172);
}
.website-logo {
  position: relative;
  flex-basis: auto;
  transition: 0.7s cubic-bezier(1, 0, 0, 1);
  height: 3em;
}
.website-logo:focus {
  outline: none;
}
.website-logo:hover {
  height: 4em;
  cursor: pointer;
}
.hidden-nav {
  display: none;
}
/*# sourceMappingURL=header-toolbar.component.css.map */
