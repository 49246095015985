<app-introduction></app-introduction>
<div class="spotlight-container">
    <div class="dot-container">
        <span class="dot" 
              *ngFor="let spotlight of spotlights"
              [ngClass]="spotlight.isVisible ? 'filled' : 'empty'"></span>
    </div>

    <ng-container *ngFor="let spotlight of spotlights">
        <app-spotlight class="fade"
                       [ngClass]="spotlight.isVisible ? 'active' : 'inactive'"
                       [headline]="spotlight.headline"
                       [description]="spotlight.description"
                       [link]="spotlight.routerLink"
                       [imageSource]="spotlight.imageSource">

        </app-spotlight>
    </ng-container>
    
    <button mat-mini-fab class="prev" (click)="previousSpotlight()"><mat-icon></mat-icon></button>
    <button mat-mini-fab class="next" (click)="nextSpotlight()"><mat-icon></mat-icon></button>
</div>
