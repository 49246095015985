<div class="ueber-uns-wrapper">
  <app-page-introduction [image]="'../../../assets/images/zimmerei_Ganz.jpg'" [useViewAsHeightCalculationBase]="true">
    <!-- <div class="zitat-text-wrapper"> -->
      <h1 class="zitat">
        {{Zitat}}
      </h1>
      <p class="zitat-Autor">
        {{ZitatAutor}}
      </p>
    <!-- </div> -->
  </app-page-introduction>
  <div class="beschreibung-wrapper">
    <p class="beschreibung">
      {{Beschreibung}}
    </p>
  </div>
</div>