<mat-toolbar color="primary" class='toolbar-sidenav'>
    <mat-toolbar-row class="toolbar-sidenav-row">
        <button [routerLink]="['/ueberUns']" mat-button color="#5D4037" class="product-tab">Über Uns</button>
        <button [matMenuTriggerFor]="menu" mat-button color="#5D4037" class="product-tab">Referenzen</button>
        <img [routerLink]="['/startPage']" class="website-logo" src="..//../../assets/images/zimmerei_logo.png">
        <button [routerLink]="['/steinbruchhaeusel']" mat-button color="#5D4037" class="product-tab">Ferienwohnung</button>
        <button [routerLink]="['/kontakt']" mat-button color="#5D4037" class="product-tab">Kontakt</button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menu="matMenu">
    <button mat-menu-item>Carports</button>
    <button mat-menu-item [routerLink]="['/daecher']">Dächer</button>
    <button mat-menu-item [routerLink]="['/steinbruchhaeusel']">Steinbruchhäusel</button>
    <button mat-menu-item [routerLink]="['/balkenBetten']">Balkenbetten</button>
</mat-menu>