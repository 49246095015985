import { AfterViewInit, Component, Input } from '@angular/core';
import { Spotlight } from './spotlight';

@Component({
	selector: 'app-body',
	templateUrl: './body.component.html',
	styleUrls: ['./body.component.scss']
})
export class BodyComponent implements AfterViewInit {
	@Input()
	isDesktop: boolean;

	spotlights: Spotlight[];

	private spotlightShowIndex = 0;

	constructor() {
		this.spotlights = [
			new Spotlight('Balkenbetten',
				'Liegen, dass sich die Balken biegen, niemand wird sie aus dem Bette kriegen',
				['/balkenBetten'],
				'..//../../assets/images/balkenbett_spotlight.jpg'),
			new Spotlight('Steinbruchhäusel',
				'Ruhe zum Wohnfühlen',
				['/steinbruchhaeusel'],
				'..//../../assets/images/steinbruchhaus_front.jpg')
		];

		this.spotlights[0].isVisible = true;
	}
	ngAfterViewInit(): void {
		this.switchSpotlights();
	}

	previousSpotlight(): void {
		this.setEverySpotlightInvisible();
		if (this.spotlightShowIndex === 0)
			this.spotlightShowIndex = this.spotlights.length-1
		else 
			this.spotlightShowIndex--;
		this.setActiveSpotlight();
	}

	nextSpotlight(): void {
		this.setEverySpotlightInvisible();
		if (this.spotlightShowIndex >= (this.spotlights.length-1))
			this.spotlightShowIndex = 0
		else 
			this.spotlightShowIndex++;
		this.setActiveSpotlight();
	}

	private switchSpotlights(): void {
		this.setEverySpotlightInvisible();

		if (this.spotlightShowIndex > this.spotlights.length - 1)
			this.spotlightShowIndex = 0;
		this.setActiveSpotlight();
		this.spotlightShowIndex++;
		setTimeout(() => this.switchSpotlights(), 3500);
	}

	private setEverySpotlightInvisible(): void {
		this.spotlights.forEach(spotlight => {
			spotlight.isVisible = false;
		});
	}

	private setActiveSpotlight(): void {
		this.spotlights[this.spotlightShowIndex].isVisible = true;
	}
}
