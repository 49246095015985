<div class="page-introduction">
	<app-page-introduction [image]="introImage" [useViewAsHeightCalculationBase]="true">
		<p class="welcome-text" [ngClass]="{'welcome-phone': !isDesktop}">
			Herzlich Willkommen im „Steinbruchhäusel“
		</p>
		<p *ngIf="isDesktop" class="welcome-text-subtitle">
			schön, dass Sie, zumindest schon mal online, unsere Gäste sind.
		</p>
	</app-page-introduction>
</div>
<div class="steinbruchhaus-intro-container" [ngClass]="{'intro-phone': !isDesktop}">
	<p class="steinbruchhaus-intro-text" [ngClass]="{'phone': !isDesktop}">
		Obwohl neueren Datums, liegt unser Häuschen in einem, tatsächlich bis in die 30-er Jahre genutzten, Steinbruch.
		Wir gehören als Rennersdorfer seit einigen Jahren offiziell zur Stadt Herrnhut, bekannt durch ihre wunderschönen
		Sterne.
		Dort befinden sich auch alle Einkaufsmöglichkeiten (Penny und Netto).
		Unser Ferienhäuschen liegt im Dreiländereck (Polen-Tschechien-Deutschland) und kann damit Ausgangspunkt für
		interessante „grenzüberschreitende“ Erlebnisse sein.
		In der näheren Umgebung (knapp 20 Minuten Fahrzeit) befindet sich der Berzdorfer See, ein herrliches
		Erholungsgebiet, entstanden durch die Flutung der Kohleabbaugrube. Den gesamten See (knapp 17 km), kann man mit
		dem Fahrrad, Inlinern oder natürlich zu Fuß umrunden und an den zahlreichen Bade-, Eis- und Imbissstellen
		pausieren.
		In entgegengesetzter Richtung ist man in ähnlicher Zeit im Zittauer Gebirge und am Olbersdorfer See, ebenfalls
		ein gefluteter ehemaliger Tagebau.
	</p>
	<p class="steinbruchhaus-intro-text greetings">
		Wir freuen uns auf Ihren Besuch!
	</p>
	<p class="steinbruchhaus-intro-text names">
		Bis bald in Rennersdorf Christian & Birgit Wabnitz
	</p>
</div>
<br>
<mat-expansion-panel hideToggle (opened)="changeColor()" (closed)="changeColor()">
	<mat-expansion-panel-header class="accent-color">
		<mat-panel-title>
			<p class="steinbruchhaus-intro-text" [ngStyle]="{'color': panelHeaderTextColor}">Bilder</p>
		</mat-panel-title>
		<mat-panel-description>
			
		</mat-panel-description>
	</mat-expansion-panel-header>
	<div class="steinbruchhaus-bilder">
		<mat-card *ngFor="let information of cottageInformations">
			<mat-card-header>
				<mat-card-title>{{information.text}}</mat-card-title>
			</mat-card-header>
			<img mat-card-image [src]="information.image" (click)="enlargeImage()">
		</mat-card>
	</div>
</mat-expansion-panel>