/* src/app/Body/referenzen/balkenbetten/balkenbetten.component.scss */
.spotlight-content-container {
  display: grid;
  grid-template-rows: auto;
  height: 100%;
  row-gap: 2em;
}
.image-container {
  display: grid;
  position: relative;
  grid-row: 1/2;
}
.spotlight-image {
  width: 100%;
  height: auto;
  Justify-self: center;
  align-self: center;
}
.spotlight-text-container {
  position: absolute;
  display: grid;
  grid-template-rows: auto;
  row-gap: 10%;
  left: 20%;
  top: 40%;
}
.desktop-header {
  font-size: 3em;
}
.phone-header {
  font-size: 2em;
}
.spotlight-header-text {
  font-family:
    Helvetica,
    Arial,
    sans-serif;
  color: rgb(27, 5, 5);
  word-break: normal;
  font-weight: 450;
  letter-spacing: 0.14em;
  margin: 0;
  grid-row: 1/2;
}
.spotlight-text {
  font-family:
    Helvetica,
    Arial,
    sans-serif;
  color: rgb(27, 5, 5);
  word-break: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  margin: 0;
  grid-row: 2/3;
}
.text-container {
  grid-row: 2/3;
  width: 60%;
  justify-self: center;
}
.text-container .kursiv {
  font-style: italic;
}
.desktop {
  font-size: 1.2em;
}
.phone {
  font-size: 1em;
}
mat-card {
  max-width: 400px;
  margin: 10px;
}
.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
/*# sourceMappingURL=balkenbetten.component.css.map */
