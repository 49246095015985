/* src/app/Body/spotlight/spotlight.component.scss */
:host {
  position: relative;
  display: flex;
}
.spotlight-wrapper {
  background-color: #FDFFFC;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: auto auto;
  margin: 10 10 10 0px;
  padding: 40px;
  width: 100%;
  height: 700px;
}
.spotlight-image {
  width: 100%;
}
.spotlight-image-wrapper {
  display: block;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 2/3;
  justify-self: center;
}
.spotlight-text-wrapper {
  display: grid;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1/2;
  align-content: center;
  align-items: center;
  justify-items: center;
}
.spotlight-headline {
  text-align: -webkit-center;
  text-align: center;
}
.spotlight-textbody {
  text-align: -webkit-center;
  text-align: center;
}
.spotlight-divider {
  background-color: #5D4037;
  display: block;
  height: 2px;
  left: 0%;
  position: absolute;
  top: 99%;
  width: 100%;
}
/*# sourceMappingURL=spotlight.component.css.map */
