/* src/app/Body/page-introduction/page-introduction.component.css */
.introduction {
  position: relative;
}
.introduction-image {
  display: block;
  position: relative;
  width: 100%;
}
.introduction-block {
  background-color: rgba(215, 204, 200, 0.600);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  left: 10%;
  position: absolute;
  top: 0%;
  width: 30%;
}
.height-isCalculated-by-view {
  height: 100vh;
  object-fit: cover;
}
.phone {
  width: 80% !important;
  padding: 10px;
  font-size: 0.9em;
  background-color: transparent;
}
/*# sourceMappingURL=page-introduction.component.css.map */
